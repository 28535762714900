<template>
  <div>
    <navigation-bar></navigation-bar>

    <div>
      <h3>{{ viszonylat }} -  {{ cikk }}</h3>
      <p class="small-spacing">{{ megrendelo }}</p>
      <p class="small-spacing">Dátum: {{ datum }}  / szállítási határidő: {{ szall_hatarido }}</p>
      <p class="small-spacing">Hivatkozási szám: {{ hivatkozasi_szam }} </p>
      <p class="small-spacing">Megjegyzés: {{ fo_megjegyzes }} </p>
    </div>
    
    <div class="container mt-5">
      <h1>Adatok módosítása</h1>
      <form id="orderForm" @submit.prevent="mentes">
 <!-- Rendszám -->
<div class="form-group row align-items-top">
  <label for="rendszamFilter" class="col-form-label col-sm-2 col-md-1 align-items-center">Rendszám</label>
  <div class="col-sm-3 col-md-2">
    <div class="input-group mb-3">
      <!-- Filter für Rendszám -->
      <input type="text" class="form-control" id="rendszamFilter" placeholder="Keresés" v-model="rendszamFilter">
      <div class="input-group-append">
        <button type="button" class="btn btn-secondary btn-sm" @click="fetchRendszam">
          <i class="fas fa-sync-alt"></i>
        </button>
        <button type="button" class="btn btn-secondary btn-sm ml-2" @click="fetchSajatRendszam">Saját</button>
      </div>
    </div>
  </div>
  
  <!-- Dropdown Auswahl von Rendszám -->
  <div class="col-sm-6 col-md-3">
    <div class="dropdown w-100">
      <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownRendszamButton" data-bs-toggle="dropdown" aria-expanded="false">
        Kiválasztás
      </button>
      <ul class="dropdown-menu w-100" aria-labelledby="dropdownRendszamButton">
        <li v-for="item in rendszamOptions" :key="item.az">
          <a class="dropdown-item" href="#" @click.prevent="selectRendszam(item)">{{ item.mr_rendszam }} ({{ item.f_nev }})</a>
        </li>
      </ul>
    </div>
  </div>

  <!-- Manuelle Eingabe für den ausgewählten oder neuen Rendszám -->
  <div class="col-sm-3 col-md-3">
    <input type="text" class="form-control" v-model="truckData.rendszam" :style="{ fontWeight: 'bold', color: 'darkgreen' }" placeholder="Rendszám">
  </div>
</div>



<!-- Pótkocsi -->
<div class="form-group row align-items-center">
  <label for="selectedPotkocsi" class="col-form-label col-sm-2 col-md-1">Pótkocsi</label>
  <div class="col-sm-10 col-md-3">
    <input type="text" class="form-control" id="selectedPotkocsi" placeholder="Rendszám megadása" v-model="truckData.potkocsi" :style="{ fontWeight: 'bold', color: 'darkgreen' }">
  </div>
</div>


<!-- Felségjel -->
<div class="form-group row align-items-center">
  <label for="selectedFelsegjel" class="col-form-label col-sm-2 col-md-1">Felségjel</label>
  <div class="col-sm-10 col-md-3">
    <input type="text" class="form-control" id="selectedFelsegjel" placeholder="Országkód megadása" v-model="truckData.felsegjel" :style="{ fontWeight: 'bold', color: 'darkgreen' }">
  </div>
</div>


<!-- Fuvarozó -->
<div class="form-group row align-items-top">
  <label for="fuvarozoFilter" class="col-form-label col-sm-2 col-md-1 d-flex align-items-center">Fuvarozó</label>
  <div class="col-sm-3 col-md-2">
    <div class="input-group mb-3">
      <!-- Filter für Fuvarozó -->
      <input type="text" class="form-control" id="fuvarozoFilter" placeholder="Keresés" v-model="fuvarozoFilter">
      <div class="input-group-append">
        <button type="button" class="btn btn-secondary btn-sm" @click="fetchFuvarozo">
          <i class="fas fa-sync-alt"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- Dropdown Auswahl von Fuvarozó -->
  <div class="col-sm-6 col-md-3">
    <div class="dropdown w-100">
      <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownFuvarozoButton" data-bs-toggle="dropdown" aria-expanded="false">
        Kiválasztás
      </button>
      <ul class="dropdown-menu w-100" aria-labelledby="dropdownFuvarozoButton">
        <li v-for="item in fuvarozoOptions" :key="item.az">
          <a class="dropdown-item" href="#" @click.prevent="selectFuvarozo(item)">{{ item.f_nev }} ({{ item.f_varos }})</a>
        </li>
      </ul>
    </div>
  </div>

  <!-- Manuelle Eingabe für Fuvarozó -->
  <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
    <input type="text" class="form-control" v-model="truckData.fuvarozo" :style="{ fontWeight: 'bold', color: 'darkgreen' }" placeholder="Fuvarozó eingeben oder auswählen">
  </div>
</div>


    <!-- Felrakás Dátuma -->
<div class="form-group row align-items-center">
  <label for="felrakasDatum" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Felrakás dátuma</label>
  <div class="col-md-3">
    <input type="date" class="form-control" id="felrakasDatum" v-model="truckData.felrakas_napja">
  </div>
</div>


<!-- Lerakás Dátuma -->
<div class="form-group row align-items-center">
  <label for="lerakasDatum" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Lerakás dátuma</label>
  <div class="col-md-3">
    <input type="date" class="form-control" id="lerakasDatum" v-model="truckData.lerakas_napja">
  </div>
</div>


 <!-- Felvett Súly -->
<div class="form-group row align-items-center">
  <label for="felvettSuly" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Felvett súly</label>
  <div class="col-md-3">
    <input type="number" class="form-control" id="felvettSuly" step="0.01" v-model="truckData.fel_suly" placeholder="Mennyiség tonnában">
  </div>
</div>


<!-- Lerakott Súly -->
<div class="form-group row align-items-center">
  <label for="lerakottSuly" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Lerakott súly</label>
  <div class="col-md-3">
    <input type="number" class="form-control" id="lerakottSuly" step="0.01" v-model="truckData.le_suly" placeholder="Mennyiség tonnában">
  </div>
</div>


<!-- Megjegyzés -->
<div class="form-group row align-items-center">
  <label for="megjegyzes" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Megjegyzés</label>
  <div class="col-md-7">
    <input type="text" class="form-control" id="megjegyzes" v-model="truckData.megjegyzes" >
  </div>
</div>


        <!-- Buttons -->
        <div class="form-group btn-container">
          <button type="button" class="btn btn-danger me-5" @click="abbrechen">Mégse</button>
          <button type="submit" class="btn btn-success" >Mentés</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axiosInstance from '@/axiosInstance';

export default {
  data() {
    return {
      rendszamFilter: '',
      rendszamOptions: [],
      selectedRendszam: '',
      selectedRendszamMegn: '',
      selectedPotkocsi: '',
      selectedFelsegjel: 'H',
      fuvarozoFilter: '',
      fuvarozoOptions: [],
      selectedFuvarozo: '',
      selectedFuvarozoNev: '',
      selectedFuvarozoVaros: '',
      felrakasDatum: '',
      lerakasDatum: '',
      felvettSuly: null,
      lerakottSuly: null,
      rakodasiSzam: '',
      billenesiKod: '',
      megjegyzes:''
    };
  },
  computed: {
    ...mapGetters(['getorderData']),
    ...mapGetters(['getUserData']),
    ...mapGetters(['getTruckData']),
    orderData() {
      return this.getorderData || {};
    },
    az() {
      return this.orderData?.az || 'Standard AZ';
    },
    cegaz() {
      return this.orderData?.cegaz || 'Standard Cegaz';
    },
    datum() {
      return this.orderData?.datum || 'Standard Datum';
    },
    viszonylat() {
      return this.orderData?.viszonylat || 'Standard Viszonylat';
    },
    napifo_az() {
      return this.orderData?.napifo_az || '0';
    },
    napimellek_az() {
      return this.orderData?.napimellek_az || '0';
    },
    tipus() {
      return this.orderData?.tipus || 'Standard Tipus';
    },
    megrendelo() {
      return this.orderData?.megrendelo || 'nem ismert';
    },
    cikk() {
      return this.orderData?.cikk || 'nincs megadva';
    },
    szall_hatarido() {
      return this.orderData?.szall_hatarido || 'hiányzik';
    },
    hivatkozasi_szam() {
      return this.orderData?.hivatkozasi_szam || '-';
    },
    fo_megjegyzes() {
      return this.orderData?.fo_megjegyzes || '-';
    },
    oldal() {
      return this.orderData?.oldal || 'Standard Oldal';
    },
    fsz() {
      return this.getUserData ? this.getUserData.fsz : null;
    },
    ceg_az() {
      return this.getUserData ? this.getUserData.ceg_az : null;
    },
    truckData() {
      return this.getTruckData || this.createNewTruckData();
    }
    
  },
  methods: {

    async fetchRendszam() {//*********************************************************************************** */

      console.log('Fetch Rendszám data');
      const postData = {
        rendszam_filter: this.rendszamFilter // Verwenden Sie v_model von Ihrem Input-Feld
      };

          console.log('postData',postData);

      try {
        let url = "/rendszam/list_rendszam.php";
        const response =  await axiosInstance.post(url, postData);

        if (response.status === 200 && response.data.data) {
          console.log('Daten erfolgreich abgerufen:', response.data.data);
          this.rendszamOptions = response.data.data;
        } else {
          console.log('Antwort nicht 200:', response);
          this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + response.statusText;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Rendszám:', error);
        this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + error.message;
      }
    },

  // Optional: Methode, um eigene (Saját) Rendszam abzurufen
  async fetchSajatRendszam() {//******************************************************************************** */
      console.log('Fetch Rendszám data');
      const postData = {
        rendszam_filter: this.rendszamFilter // Verwenden Sie v_model von Ihrem Input-Feld
      };

          console.log('postData',postData);

      try {
        let url = "/rendszam/list_sajatrendszam.php";
        const response =  await axiosInstance.post(url, postData);

        if (response.status === 200 && response.data.data) {
          console.log('Daten erfolgreich abgerufen:', response.data.data);
          this.rendszamOptions = response.data.data;
        } else {
          console.log('Antwort nicht 200:', response);
          this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + response.statusText;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Rendszám:', error);
        this.errorMessage = 'Fehler beim Abrufen der Rendszám: ' + error.message;
      }
    },
  selectRendszam(item) {//************************************************************************************* */
  this.selectedRendszam = item.az;
  this.selectedRendszamMegn = item.mr_rendszam;
  this.truckData.rendszam = item.mr_rendszam; // Synchronisieren mit truckData
  console.log('Ausgewählter Rendszam:', item.mr_rendszam);

  
      // Überprüfen, ob item.potkocsi existiert und nicht leer ist
      if (item.potkocsi && item.potkocsi !== '') {
        this.selectedPotkocsi = item.potkocsi;
        this.truckData.potkocsi = item.potkocsi; // Aktualisiere auch truckData.potkocsi
      } else {
        // Wenn kein potkocsi vorhanden ist, setze es auf einen Standardwert oder leere es
        this.truckData.potkocsi = '';
      }

},

    createNewTruckData() {//********************************************************************************* */
      return {
        az: null,
        fo_az: null,
        rendsz_az: null,
        rendszam: '',
        potkocsi: '',
        felsegjel: 'H',
        fuvarozo: '',
        felrakas_napja: null,
        lerakas_napja: null,
        fel_suly: null,
        le_suly: null,
        rakodasi_szam: '',
        billenes_kod: '',
        megjegyzes: '',
        datum: this.todayDate,
        status: null,
        storno: null,
        fordulo: 1,
        ceg_az: null,
        made: '',
        felh: '',
        timest: '',
        mod_felh: '',
        sajat: null,
        sorrend: null,
        ev: null,
        ho: null,
        het: null,
        het_napja: null,
        m_tipmenny: null
      };
    },
    abbrechen() {//****************************************************************************************** */
      if (confirm("Valóban be akarja bejezni az adatmegadást mentés nélkül?")) {
        this.$router.go(-1);
      }
    },
    async mentes() {//**************************************************************************** */
    
    console.log('editTruck mentes start -----');

console.log('this.truckData.rendszam',this.truckData.rendszam);

      const postData = {
          az: this.truckData.az,
          fo_az: this.truckData.fo_az,
          rendsz_az: this.truckData.rendsz_az,
          rendszam: this.truckData.rendszam,
          potkocsi: this.truckData.potkocsi,
          felsegjel: this.truckData.felsegjel,
          fuv_az: this.truckData.fuv_az,
          fuvarozo: this.truckData.fuvarozo,
          felrakas_napja: this.truckData.felrakas_napja,
          lerakas_napja: this.truckData.lerakas_napja,
          fel_suly: this.truckData.fel_suly || 0,
          le_suly: this.truckData.le_suly || 0,
          rakodasi_szam: this.truckData.rakodasi_szam,
          billenes_kod: '', //this.truckData.billenesi_kod,
          status: this.truckData.status,
          storno: this.truckData.storno || 0,
          felh: this.truckData.felh,
          mod_felh: this.truckData.mod_felh,
          sajat: this.truckData.sajat,
          fordulo: this.truckData.fordulo,
          megjegyzes: this.truckData.megjegyzes,
          sorrend: this.truckData.sorrend,
          datum: this.truckData.datum,
          ev: this.truckData.ev,
          ho: this.truckData.ho,
          het: this.truckData.het,
          het_napja: this.truckData.het_napja,
          m_tipmenny: this.truckData.m_tipmenny,
          ceg_az: this.truckData.ceg_az
        };

    
            console.log('editTruck Data:', postData);

            try {
              // API-Aufruf mit den Truck-Daten
              const response = await axiosInstance.post('/napi_mellek/update_napimellek.php', postData);

              // Überprüfe, ob die Antwort erfolgreich war
              if (response.status === 200 && response.data.success) {
                console.log('Daten erfolgreich aktualisiert:', response.data);
               // alert('Die Daten wurden erfolgreich aktualisiert.');
                this.$router.go(-1); // Zurück zur vorherigen Seite navigieren
              } else {
                console.error('Fehler beim Aktualisieren der Daten:', response.data);
                alert('Hiba az adatok módosítása során: ' + (response.data.message || 'Ismeretlen hiba (editTruck.vue)'));
              }
            } catch (error) {
              // Fehlerbehandlung für den API-Aufruf
              if (error.response) {
                console.error('Fehlerstatus:', error.response.status);
                console.error('Fehlerdaten:', error.response.data);
                alert('Hiba a mentés során: ' + error.response.data.message || 'Ismeretlen hiba (editTruck.vue)');
              } else if (error.request) {
                console.error('Keine Antwort erhalten:', error.request);
                alert('A szerver nem válaszol.');
              } else {
                console.error('Fehlermeldung:', error.message);
                alert('Hiba: ' + error.message);
              }
            }


    }
  
    
  },
  mounted() {
    console.log("editTruck.vue mounted");
    if (this.truckData) {
    this.selectedRendszamMegn = this.truckData.rendszam;
    this.selectedPotkocsi = this.truckData.potkocsi;
    this.selectedFelsegjel = this.truckData.felsegjel;
    this.selectedFuvarozo = this.truckData.fuvarozo;
    this.selectedFuvarozoNev = this.truckData.fuvarozo;
    this.truckData.felrakas_napja = this.truckData.felrakas_napja || ''; // Felrakás Dátuma setzen
    this.truckData.lerakas_napja = this.truckData.lerakas_napja || ''; // Lerakás Dátuma setzen
    this.felvettSuly = this.truckData.fel_suly;
    this.lerakottSuly = this.truckData.le_suly;
    this.megjegyzes = this.truckData.megjegyzes;
  }

  }
};
</script>

<style scoped>
.small-spacing {
  margin-bottom: 2px;
}
.input-group, .btn-group, .dropdown {
  display: flex;
  align-items: center;
  height: 38px;
}
.input-group .btn-sm {
  padding: 6px 12px;
  margin-left: 5px;
  margin-right: 5px;
}
.dropdown-toggle {
  padding: 6px 12px;
  margin-left: 5px;
}
.form-group.row {
  display: flex;
  align-items: center;
}
.input-group-append {
  display: flex;
  align-items: center;
}
.form-group .col-md-1, .form-group .col-md-2, .form-group .col-md-3, .form-group .col-sm-3 {
  display: flex;
  align-items: center;
  padding-right: 0;
}
.input-group .form-control, .input-group .btn, .input-group .dropdown-toggle {
  align-self: center;
}
p {
  margin-bottom: 1px;
}
</style>
