<template>
  
  <div>
    <navigation-bar></navigation-bar>
    <!-- Der Rest Ihres Komponenten-Templates -->
  </div>

    <!-- Main Content -->
    <div class="container mt-5">
      <h1 style="margin-top: 0px;">Fuvarterv rögzítése</h1>
        <form id="orderForm" @submit.prevent="mentesx">
            <!-- Existing input-group sections for other filters and dropdowns -->
<!-- megrendelo *************************************************************************************************************************-->
<div class="form-group row align-items-center">
        <label for="megrendelo_filter" class="col-form-label col-sm-2 col-md-1 d-flex align-items-center">Megrendelő</label>
        <div class="col-sm-3 col-md-2">
          <div class="input-group mb-3">
            <input type="text" class="form-control" id="megrendelo_filter" placeholder="Keresés">
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary btn-sm" @click="fetchMegrendelo">
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-3 col-md-3 d-flex align-items-center">
          <div class="btn-group ml-2">
            <button type="button" class="btn btn-primary custom-btn-sm mr-4" @click="setStandardValue(1)"  :style="{ fontSize: '12px', padding: '0px 6px 0px 6px', lineHeight: '1.0' }">Viterra</button>
            <button type="button" class="btn btn-primary custom-btn-sm mr-4" @click="setStandardValue(2)"  :style="{ fontSize: '12px', padding: '0px 6px 0px 6px', lineHeight: '1.0' }">Cargill</button>
            <button type="button" class="btn btn-primary custom-btn-sm" @click="setStandardValue(3)"  :style="{ fontSize: '12px', padding: '0px 6px 0px 6px', lineHeight: '1.0' }">UBM Grain</button>
          </div>
        </div>
        <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
          <div class="dropdown w-100">
            <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              Kiválasztás
            </button>
            <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
              <li v-for="item in megrendeloOptions" :key="item.v_kod">
                <a class="dropdown-item" href="#" @click.prevent="selectMegrendelo(item)">{{ item.v_nev1 }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
            <input type="text" class="form-control" v-model="selectedMegrendeloName" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
        </div>
      </div>

<!-- felrako *************************************************************************************************************************-->            
<div class="form-group row align-items-center">
        <label for="felrako_filter" class="col-form-label col-sm-2 col-md-1 d-flex align-items-center">Felrakó</label>
        <div class="col-sm-3 col-md-2">
          <div class="input-group mb-3">
            <input type="text" class="form-control" id="felrako_filter" placeholder="Keresés">
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary btn-sm" @click="fetchFelrako">
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-3 col-md-3 d-flex align-items-center">
          <div class="btn-group ml-2">
            <button type="button" class="btn btn-primary custom-btn-sm mr-3" @click="setStandardValueFelrako(1)" :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.0' }" >Slo-Koper</button>
            <button type="button" class="btn btn-primary custom-btn-sm mr-3" @click="setStandardValueFelrako(2)"  :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">H-Foktő</button>
            <button type="button" class="btn btn-primary custom-btn-sm mr-3" @click="setStandardValueFelrako(3)"  :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">H-Szabadegyháza</button>
          </div>
        </div>
        <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
          <div class="dropdown w-100">
            <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownFelrakoButton" data-bs-toggle="dropdown" aria-expanded="false">
              Kiválasztás
            </button>
            <ul class="dropdown-menu w-100" aria-labelledby="dropdownFelrakoButton">
              <li v-for="item in felrakoOptions" :key="item.tav_az">
                <a class="dropdown-item" href="#" @click.prevent="selectFelrako(item)">{{ item.tav_megn }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
          <input type="text" class="form-control" v-model="selectedFelrakoName" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
        </div>
      </div>

<!-- lerako *************************************************************************************************************************-->            
<div class="form-group row align-items-center">
        <label for="lerako_filter" class="col-form-label col-sm-2 col-md-1 d-flex align-items-center">Lerakó</label>
        <div class="col-sm-3 col-md-2">
          <div class="input-group mb-3">
            <input type="text" class="form-control" id="lerako_filter" placeholder="Keresés">
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary btn-sm" @click="fetchLerako">
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-3 col-md-3 d-flex align-items-center">
          <div class="btn-group ml-2">
            <button type="button" class="btn btn-primary custom-btn-sm mr-2" @click="setStandardValueLerako(1)" :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">H-Duna-földvár</button>
            <button type="button" class="btn btn-primary custom-btn-sm mr-2" @click="setStandardValueLerako(2)" :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">H-Szabad-egyháza</button>
            <button type="button" class="btn btn-primary custom-btn-sm" @click="setStandardValueLerako(3)" :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">H-Kiskun-félegyháza</button>
          </div>
        </div>
        <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
          <div class="dropdown w-100">
            <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownLerakoButton" data-bs-toggle="dropdown" aria-expanded="false">
              Kiválasztás
            </button>
            <ul class="dropdown-menu w-100" aria-labelledby="dropdownLerakoButton">
              <li v-for="item in lerakoOptions" :key="item.tav_az">
                <a class="dropdown-item" href="#" @click.prevent="selectLerako(item)">{{ item.tav_megn }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
          <input type="text" class="form-control" v-model="selectedLerakoName" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
        </div>
      </div>
<!-- szallitas_kezdete ************************************************************************************************************ -->
<div class="form-group row align-items-top">
  <label for="datum" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Szállítás kezdete</label>
  <div class="col-md-3">
    <input type="date" class="form-control" id="datum" v-model="datum_ertek" required>
  </div>

  <div class="col-md-3">
    <button class="btn btn-primary mr-2" @click="setToday_datum">Mai nap</button>
    <button class="btn btn-secondary" @click="incrementDay_datum">+1 nap</button>
  </div>

</div>
<!--   cikk **********************************************************************************************************************-->            
<div class="form-group row align-items-center">
    <label for="cikk_filter" class="col-form-label col-sm-2 col-md-1 d-flex align-items-center">Cikk</label>
    <div class="col-sm-3 col-md-2">
      <div class="input-group mb-3">
        <input type="text" class="form-control" id="cikk_filter" placeholder="Keresés">
        <div class="input-group-append">
          <button type="button" class="btn btn-secondary btn-sm" @click="fetchCikk">
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 d-flex align-items-center">
      <div class="btn-group ml-2">
        <button type="button" class="btn btn-primary custom-btn-sm mr-2" @click="setStandardValueCikk(1)" :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">szójadara</button>
        <button type="button" class="btn btn-primary custom-btn-sm mr-2" @click="setStandardValueCikk(2)" :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">kukorica</button>
        <button type="button" class="btn btn-primary custom-btn-sm" @click="setStandardValueCikk(3)" :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">napradara</button>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
      <div class="dropdown w-100">
        <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownCikkButton" data-bs-toggle="dropdown" aria-expanded="false">
          Kiválasztás
        </button>
        <ul class="dropdown-menu w-100" aria-labelledby="dropdownCikkButton">
          <li v-for="item in cikkOptions" :key="item.cikk_az">
            <a class="dropdown-item" href="#" @click.prevent="selectCikk(item)">{{ item.cikk_megn }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
      <input type="text" class="form-control" v-model="selectedCikkName" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
    </div>
  </div>

<!--   Mennyiség ***************************************************************************************************************-->
<div class="form-group row align-items-center">
    <label for="mennyiseg" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Mennyiség</label>
    <div class="col-sm-3 col-md-3">
      <input type="number" class="form-control" id="mennyiseg" v-model="suly_ertek" placeholder="tonna" required @focus="clear_to">
    </div>
  </div>
<!--   km ***************************************************************************************************************-->
<div class="form-group row align-items-center">
    <label for="tavolsag" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Távolság</label>
    <div class="col-sm-3 col-md-3">
      <input type="number" class="form-control" id="tavolsag" v-model="km_ertek" placeholder="km" required @focus="clear_km">
    </div>
  </div>
<!--   felrako hely  ******************************************************************************************************** -->
<div class="form-group row align-items-center">
    <label for="felhely" class="col-form-label col-md-2 d-flex align-items-center">Felrakó hely</label>
    <div class="col-md-1">
      <button type="button" class="btn btn-secondary btn-sm" @click="fetchFelhely">
        <i class="fas fa-sync-alt"></i>
      </button>
    </div>
    <div class="col-md-6">
      <div class="dropdown w-100">
        <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownFelhelyButton" data-bs-toggle="dropdown" aria-expanded="false">
          Kiválasztás
        </button>
        <ul class="dropdown-menu w-100" aria-labelledby="dropdownFelhelyButton">
          <li v-for="item in felhelyOptions" :key="item.hely_az">
            <a class="dropdown-item" href="#" @click.prevent="selectFelHely(item)">
              {{ item.hely_ceg }} - {{ item.hely_cim }} - {{ item.hely_megj }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-3">
      <input type="text" class="form-control mt-2" v-model="selectedFelHelyName" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
    </div>
  </div>
<!--   lerako hely  **********************************************************************************************************-->            
<div class="form-group row align-items-center">
    <label for="lehely" class="col-form-label col-md-2 d-flex align-items-center">Lerakó hely</label>
    <div class="col-md-1">
      <button type="button" class="btn btn-secondary btn-sm" @click="fetchLehely">
        <i class="fas fa-sync-alt"></i>
      </button>
    </div>
    <div class="col-md-6">
      <div class="dropdown w-100">
        <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownLehelyButton" data-bs-toggle="dropdown" aria-expanded="false">
          Kiválasztás
        </button>
        <ul class="dropdown-menu w-100" aria-labelledby="dropdownLehelyButton">
          <li v-for="item in lehelyOptions" :key="item.hely_az">
            <a class="dropdown-item" href="#" @click.prevent="selectLeHely(item)">
              {{ item.hely_ceg }} - {{ item.hely_cim }} - {{ item.hely_megj }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-3">
      <input type="text" class="form-control mt-2" v-model="selectedLeHelyName" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
    </div>
  </div>
<!-- határidő ************************************************************************************************************ -->
<div class="form-group row align-items-center">
  <label for="hatarido" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Szállítás határideje</label>
  <div class="col-md-3">
    <input type="date" class="form-control" id="hatarido" v-model="hatarido_ertek" >   <!--required-->
  </div>

  <div class="col-md-3">
    <button class="btn btn-primary mr-2" @click="setToday">Mai nap</button>
    <button class="btn btn-secondary" @click="incrementDay">+1 nap</button>
  </div>

</div>
<!-- hivatkozási szám ************************************************************************************************************ -->
<div class="form-group row align-items-center">
  <label for="hivatkozasi_szam" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Hivatkozási Szám</label>
  <div class="col-md-3">
    <input type="text" class="form-control" id="hivatkozasi_szam" v-model="hivatkozasi_szam_ertek">
  </div>
</div>
<!--  Megrendelöi   Ár tipus *******************************************************************************************************-->            
<div class="form-group row align-items-center">
    <label for="tip_filter" class="col-form-label col-sm-2 col-md-1 d-flex align-items-center">Megrendelői ártípus</label>
    <div class="col-sm-3 col-md-2">
      <div class="input-group mb-3">
        <input type="text" class="form-control" id="tip_filter" placeholder="Keresés">
        <div class="input-group-append">
          <button type="button" class="btn btn-secondary btn-sm" @click="fetchArTipus">
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 d-flex align-items-center">
      <div class="btn-group ml-2">
        <button type="button" class="btn btn-primary custom-btn-sm mr-2" @click="setStandardValueTip(1)" :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">Fuvar Ft/to</button>
        <button type="button" class="btn btn-primary custom-btn-sm" @click="setStandardValueTip(2)" :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">Fuvar €/to</button>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
      <div class="dropdown w-100">
        <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownTipButton" data-bs-toggle="dropdown" aria-expanded="false">
          Kiválasztás
        </button>
        <ul class="dropdown-menu w-100" aria-labelledby="dropdownTipButton">
          <li v-for="item in tipOptions" :key="item.tip_az">
            <a class="dropdown-item" href="#" @click.prevent="selectTip(item)">
              {{ item.tip_megn }} - {{ item.tip_egyseg }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
      <input type="text" class="form-control" v-model="selectedTipName" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
    </div>
  </div>


<!--    megrendeloi ár *********************************************************************************-->          
<div class="form-group row align-items-center">
  <label for="megrendelo_ar" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Megrendelői ár</label>
  <div class="col-md-3">
    <input type="number" class="form-control" id="megrendelo_ar" step="0.01" v-model="megrendelo_ar_ertek" @focus="clear_ar">
  </div>
</div>


<!-- Alvállalkozói ár tipus *******************************************************************************************************-->            
<div class="form-group row align-items-center">
    <label for="alvTip_filter" class="col-form-label col-sm-2 col-md-1 d-flex align-items-center">Alvállalkozói ártípus</label>
    <div class="col-sm-3 col-md-2">
        <div class="input-group mb-3">
            <input type="text" class="form-control" id="alvTip_filter" placeholder="Keresés">
            <div class="input-group-append">
                <button type="button" class="btn btn-secondary btn-sm" @click="fetchAlvArTipus">
                    <i class="fas fa-sync-alt"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-sm-3 col-md-3 d-flex align-items-center">
        <div class="btn-group ml-2">
            <button type="button" class="btn btn-primary custom-btn-sm mr-2" @click="setStandardValueAlvTip(1)" :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">Fuvar Ft/to</button>
            <button type="button" class="btn btn-primary custom-btn-sm" @click="setStandardValueAlvTip(2)" :style="{ fontSize: '12px', padding: '4px 4px', lineHeight: '1.2' }">Fuvar €/to</button>
        </div>
    </div>
    <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
        <div class="dropdown w-100">
            <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownAlvTipButton" data-bs-toggle="dropdown" aria-expanded="false">
                Kiválasztás
            </button>
            <ul class="dropdown-menu w-100" aria-labelledby="dropdownAlvTipButton">
                <li v-for="itemalv in alvTipOptions" :key="itemalv.tip_az">
                    <a class="dropdown-item" href="#" @click.prevent="selectAlvTip(itemalv)">
                        {{ itemalv.tip_megn }} - {{ itemalv.tip_egyseg }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-sm-3 col-md-3 mt-2 mt-md-0">
        <input type="text" class="form-control" v-model="selectedAlvTipName" readonly :style="{ fontWeight: 'bold', color: 'darkgreen' }">
    </div>
</div>





<!--    alv ár *********************************************************************************-->                      
<div class="form-group row align-items-center">
  <label for="alv_dij" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Alapértelm. Alv díj</label>
  <div class="col-md-3">
    <input type="number" class="form-control" id="alv_dij" step="0.01" v-model="alv_dij_ertek" @focus="clear_alvar">
  </div>
</div>

<!-- megjegyzes ************************************************************************************************************ -->
<div class="form-group row align-items-center">
  <label for="megjegyzes" class="col-form-label col-sm-2 col-md-2 d-flex align-items-center">Megjegyzés</label>
  <div class="col-md-10">
    <input type="text" class="form-control" id="megjegyzes" v-model="megjegyzes">
  </div>
</div>


<!--    mentés  *********************************************************************************-->                      
        <div class="form-group btn-container">
            <button type="button" class="btn btn-danger me-5" @click="abbrechen">Mégse</button>
            <button type="submit" class="btn btn-success" @click="mentes">Mentés</button>
            
        </div>

        </form>



        <div id="message" class="mt-3"></div>
    </div>


</template>

<script>
import axiosInstance from '@/axiosInstance';
import { mapGetters } from 'vuex';


export default {
  name: 'NewOrder',

    data(){
       return{
        megrendeloOptions: [],
                felrakoOptions: [],
                lerakoOptions: [],
                cikkOptions: [],
                felhelyOptions: [],
                lehelyOptions: [],
                tipOptions: [], // Data property for ar_tipus
                valasz: [],
                selectedMegrendelo: '', // Variable zur Speicherung der ausgewählten Option
                selectedMegrendeloName: '',
                selectedFelrako: '',
                selectedFelrakoName: '',
                selectedLerako: '',
                selectedLerakoName: '',
                selectedCikk:'',
                selectedCikkName: '',
                selectedTip: '',
                selectedTipName: '',
                selectedFelHely:'',
                selectedFelHelyName: '',
                selectedLeHely:'',
                selectedLeHelyName: '',
                selectedMegrendeloVnev1:'',
                selectedFelrakoMegn:'',
                selectedLerakoMegn:'',
                selectedCikkMegn:'',
                selectedTipMegn:'',
                selectedTipMenny: 1,
                felhelyCeg_Cim:'',
                lehelyCeg_Cim:'',
                  suly_ertek: 0,
                  km_ertek: 0,
                  hivatkozasi_szam_ertek: '',
                  megrendelo_ar_ertek: 0,
                  alv_dij_ertek: 0,     
                  hatarido_ertek: null,
                  datum_ertek: null,
                  megjegyzes:'',

                alvTipOptions: [], // Optionen für Alvállalkozói ártípus
                selectedAlvTip: '', // Ausgewählte Option
                selectedAlvTipMegn: '',
                selectedAlvTipName: '', // Name der ausgewählten Option   nem kell
                mar_artipaz: 0, // ID des ausgewählten Alvállalkozói ártípus
                mar_egysegarme: '' // Preis des ausgewählten Alvállalkozói ártípus
                
                              

       } 
    },
    methods: {



                abbrechen() {
                        if (confirm("Valóban be akarja bejezni az adatmegadást mentés nélkül?")) {
                            this.$router.push('/menu'); 
                        }
                },

                async mentes(){//****************************************************************************************************************** */
                
                 

                    if (
                           !this.selectedMegrendelo 
                        || !this.selectedFelrako 
                        || !this.selectedLerako 
                        || !this.selectedCikk 
                        || !this.selectedTip 
                        || !this.suly_ertek 
                        || !this.km_ertek 
                        || !this.megrendelo_ar_ertek 
                        || !this.alv_dij_ertek 
                        || !this.hatarido_ertek
                        || !this.datum_ertek 
                        || !this.selectedAlvTip // Validierung für Alvállalkozói ártípus hinzugefügt
                        
                                          
                      ) {
                        alert("Az adatmegadás hiányos.");
                        return;
                    }

                    console.log('Inputs sind gesetzt.');

                    let suly = parseFloat(this.suly_ertek) || 0;
  
                // Wenn der Wert von 'mennyiseg' größer als 200 ist, zeige eine Warnung
                if (suly > 200) {
                  let confirmation = confirm("A mennyiség meghaladja a 200 tonnát. Biztosan folytatni akarja?");
                  if (!confirmation) {
                    return; // Falls der Nutzer "Nem" wählt, bricht die Methode hier ab
                  }
                }


                    this.getSelectedMegrendeloVnev1();
                    this.getSelectedFelrakoMegn();
                    this.getSelectedLerakoMegn();
                    this.getSelectedCikkMegn();
                    this.getSelectedTipMegn();
                    this.getSelectedTipMenny();
                    this.getSelectedAlvTipMegn();
                    this.getSelectedFelHelyMegn();
                    this.getSelectedLeHelyMegn();

                    console.log('this.slelectedTipMenny'+this.selectedTipMenny);

                    console.log('kiválasztott Megrendelo:', this.selectedMegrendeloVnev1);
                    console.log('kiválasztott Felrako:', this.selectedFelrakoMegn);
                    console.log('kiválasztott Lerako:', this.selectedLerakoMegn);

                    console.log('felhely',this.selectedFelHely);

                    let mfelhelyaz=this.selectedFelHely;
                        try{
                            if(!mfelhelyaz>0){
                                mfelhelyaz=0;
                            }
                        }catch(error)
                        {
                            mfelhelyaz=0;
                        }

                    let mlehelyaz=this.selectedLeHely;

                        try{
                            if(!mlehelyaz>0){
                                mlehelyaz=0;
                            }
                        }catch(error){
                            mlehelyaz=0;
                        }

                        let selectedTipMenny = parseFloat(this.selectedTipMenny) || 1;
                        let msuly = parseFloat(this.suly_ertek) || 0;

                        let statmenny = selectedTipMenny * msuly;
                    console.log('statmenny',statmenny);                        

                    const postData={
                        m_az:0, 
                        m_megrendeloaz: this.selectedMegrendelo, 
                        megrendelo:this.selectedMegrendeloVnev1, 
                        m_felrakoaz: this.selectedFelrako, 
                        felrako:this.selectedFelrakoMegn, 
                        m_lerakoaz: this.selectedLerako, 
                        lerako:this.selectedLerakoMegn, 
                        m_datumtol:this.datum_ertek, 
                        m_cikkaz:this.selectedCikk, 
                        cikk:this.selectedCikkMegn,
                        m_suly:this.suly_ertek, 
                        m_km:this.km_ertek, 
                        m_felhelyaz:mfelhelyaz, 
                        fhely:this.felhelyCeg_Cim, 
                        m_lehelyaz: mlehelyaz, 
                        lhely:this.lehelyCeg_Cim, 
                        szall_hatarido:this.hatarido_ertek, 
                        hivatkozasi_szam: this.hivatkozasi_szam_ertek, 
                        m_artipaz: this.selectedTip, 
                        m_egysegarme:this.selectedTipMegn, 
                        m_aregysegar:this.megrendelo_ar_ertek, 
                        mar_egysegar:this.alv_dij_ertek, 
                        m_fuvarszaz:this.szem_az, 
                        fuvarszervezo:this.fsz, 
                        storno:0, 
                        m_cegaz:this.ceg_az,
                        megjegyzes:this.megjegyzes,
                        felh: this.fsz,
                        mod_felh: this.fsz,
                        stat_menny:   statmenny,
                        m_tipmenny: this.selectedTipMenny,
                          
                        mar_artipaz: parseInt(this.selectedAlvTip, 10), 
                        mar_egysegarme: this.selectedAlvTipMegn,
                        csoport: this.csoport
                    };

                        //Logging postData before sending the request
                        console.log('Post Data:', JSON.stringify(postData));

                        try {

                            console.log('start axios');

                            const response = await axiosInstance.post('/megrendeles/api_create_megrendeles.php', postData);

                            console.log('Axios request completed');

                            this.valasz = response.data;
                            console.log('Status:', response.status);
                            console.log('Status Text:', response.statusText);
                            console.log('Headers:', response.headers);
                            console.log('Data:', response.data);

                            if (response.data.success) {
                                console.log('minden ok, az uj ID:', response.data.id);
                                alert("Az adatok mentésre kerültek.");
                            //    this.mentesx(); 
                            } else {
                                console.log('Hiba.');
                                alert("Hiba történt a mentés során, értesítse a programozót.");
                            }

                            console.log('vege');
                            console.log(this.valasz);



                        } catch (error) {
                            if (error.response) {
                                // Server antwortete mit einem Statuscode, der außerhalb des Bereichs von 2xx liegt
                                console.error('Error Status:', error.response.status);
                                console.error('Error Data:', error.response.data);
                                console.error('Error Headers:', error.response.headers);
                                alert("Hiba történt a mentés során, értesítse a programozót. " +error.response.status+" " +error.response.data+" "+error.response.headers);
                            } else if (error.request) {
                                // Anfrage wurde gesendet, aber keine Antwort erhalten
                                console.error('No Response:', error.request);
                                alert("Hiba történt a mentés során, értesítse a programozót. " +error.request);
                            } else {
                                // Fehler bei der Erstellung der Anfrage
                                console.error('Error Message:', error.message);
                                alert("Hiba történt a mentés során, értesítse a programozót. " +error.message);
                            }
                            console.error('Config:', error.config);
                            alert("Hiba történt a mentés során, értesítse a programozót. " +error.config);
                        }

                    console.log('mentes vege');


                    this.$router.push('/menu');

                },


                selectMegrendelo(item) {
                    this.selectedMegrendelo = item.v_kod;
                    this.selectedMegrendeloName = item.v_nev1;
                },
                selectFelrako(item) {
                    this.selectedFelrako = item.tav_az;
                    this.selectedFelrakoName = item.tav_megn;
                },
                selectLerako(item) {
                    this.selectedLerako = item.tav_az;
                    this.selectedLerakoName = item.tav_megn;
                },
                selectCikk(item) {
                    this.selectedCikk = item.cikk_az;
                    this.selectedCikkName = item.cikk_megn;
                },
                selectFelHely(item) {
                  this.selectedFelHely = item.hely_az;
                  this.selectedFelHelyName = `${item.hely_ceg} - ${item.hely_cim} - ${item.hely_megj}`;
                },
                selectLeHely(item) {
                  this.selectedLeHely = item.hely_az;
                  this.selectedLeHelyName = `${item.hely_ceg} - ${item.hely_cim} - ${item.hely_megj}`;
                },
                selectTip(item) {
                  this.selectedTip = item.tip_az;
                  this.selectedTipName = `${item.tip_megn} - ${item.tip_egyseg}`;
                },

                setToday() {
                  const today = new Date();
                  this.hatarido_ertek = today.toISOString().substr(0, 10); // Setzt das aktuelle Datum
                },
                incrementDay() {
                  let date = new Date(this.hatarido_ertek);
                  date.setDate(date.getDate() + 1); // Erhöht das Datum um einen Tag
                  this.hatarido_ertek = date.toISOString().substr(0, 10);
                },
                setToday_datum() {
                  const today = new Date();
                  this.datum_ertek = today.toISOString().substr(0, 10); // Setzt das aktuelle Datum
                },
                incrementDay_datum() {
                  let date = new Date(this.datum_ertek);
                  date.setDate(date.getDate() + 1); // Erhöht das Datum um einen Tag
                  this.datum_ertek = date.toISOString().substr(0, 10);
                },
                  getSelectedMegrendeloVnev1() {
                    const selected = this.megrendeloOptions.find(item => item.v_kod === this.selectedMegrendelo);
                    this.selectedMegrendeloVnev1 = selected ? selected.v_nev1 : '';
                },

                getSelectedFelrakoMegn() {
                    const selected = this.felrakoOptions.find(item => item.tav_az === this.selectedFelrako);
                    this.selectedFelrakoMegn = selected ? selected.tav_megn : '';
                },
                getSelectedLerakoMegn() {
                    const selected = this.lerakoOptions.find(item => item.tav_az === this.selectedLerako);
                    this.selectedLerakoMegn = selected ? selected.tav_megn : '';
                },
                getSelectedCikkMegn() {
                    const selected = this.cikkOptions.find(item => item.cikk_az === this.selectedCikk);
                    this.selectedCikkMegn = selected ? selected.cikk_megn: '';
                },
                getSelectedTipMegn() {
                    const selected = this.tipOptions.find(item => item.tip_az === this.selectedTip);
                    this.selectedTipMegn = selected ? selected.tip_megn  + ' - ' + selected.tip_egyseg: '';
                },
                getSelectedAlvTipMegn() {
                    if (this.alvTipOptions && this.alvTipOptions.length > 0) { // Sicherstellen, dass alvTipOptions definiert und nicht leer ist
                        const selected = this.alvTipOptions.find(item => item.tip_az === this.selectedAlvTip);
                        this.selectedAlvTipMegn = selected ? selected.tip_megn + ' - ' + selected.tip_egyseg : ''; // Setze den Wert oder Standardwert
                    } else {
                        console.error('AlvTipOptions ist nicht definiert oder leer.');
                        this.selectedAlvTipMegn = ''; // Setze einen Standardwert, wenn alvTipOptions undefined ist
                    }
                },
                getSelectedTipMenny() {
                    const selected = this.tipOptions.find(item => item.tip_az === this.selectedTip);
                    this.selectedTipMenny = selected ? selected.m_tipmenny: 1;
                },
                 
                getSelectedFelHelyMegn(){
                    const selected = this.felhelyOptions.find(item => item.hely_az === this.selectedFelHely);
                    this.felhelyCeg_Cim = selected ? selected.hely_ceg +' - ' + selected.hely_cim : '';
                },
                getSelectedLeHelyMegn(){
                    const selected = this.lehelyOptions.find(item => item.hely_az === this.selectedLeHely);
                    this.lehelyCeg_Cim = selected ? selected.hely_ceg +' - ' + selected.hely_cim : '';
                },

                async fetchMegrendelo() {
                    const v_nev1_filter = document.getElementById('megrendelo_filter').value;
                    const postData = {
                        v_cegaz: 1,
                        v_nev1_filter: v_nev1_filter
                    };
                    console.log(JSON.stringify(postData));
                    let url="/megrendelo/list_megrendelo.php";
                    const response = await axiosInstance.post(url, postData);
                    console.log('response',response);
                    if (response.status === 200) {
                        console.log('belepett');
                        this.megrendeloOptions = response.data.data;
                        console.log(this.megrendeloOptions);
                    } else {
                        console.log('nem 200');
                        this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                    }
                    
                },

                async fetchFelrako(){
                    const tav_megn_filter = document.getElementById('felrako_filter').value;
                    const postData = {
                        tav_cegaz: 1,  // Fixierter Wert für v_cegaz
                        tav_megn_filter: tav_megn_filter  // Dynamischer Wert aus dem Filter
                       };
                    //  console.log(JSON.stringify(postData))

                        let url="/tav/list_tav.php";
                        const response =await axiosInstance.post(url, postData);
                        if (response.status === 200) {
                            this.felrakoOptions = response.data.data;
                           // console.log(this.felrakoOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }

                 }, 

                 async  fetchLerako(){
                    const tav_megn_filterle = document.getElementById('lerako_filter').value;
                    const postData = {
                        tav_cegaz: 1,  // Fixierter Wert für v_cegaz
                        tav_megn_filter: tav_megn_filterle  // Dynamischer Wert aus dem Filter
                       };
                        //  console.log(JSON.stringify(postData))

                        let url="/tav/list_tav.php";
                        const response =await  axiosInstance.post(url, postData);
                        if (response.status === 200) {
                            this.lerakoOptions = response.data.data;
                         //   console.log(this.lerakoOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }

                 }, 
                 async  fetchCikk(){

                    const cikk_megn_filter = document.getElementById('cikk_filter').value;
                    const postData = {
                        cikk_cegaz: 1,  // Fixierter Wert für v_cegaz
                        cikk_megn_filter: cikk_megn_filter  // Dynamischer Wert aus dem Filter
                       };
                        //  console.log(JSON.stringify(postData))

                        let url="/cikk/list_cikk.php";
                        const response =await  axiosInstance.post(url, postData);
                        if (response.status === 200) {
                            this.cikkOptions = response.data.data;
                         //   console.log(this.cikkOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }
                 }, 
                 
                 getTavAz() {
                        // Hier können Sie den tav_az-Wert weiterverarbeiten oder anzeigen
                    console.log('Selected tav_az:', this.selectedFelrako);
                },

                async  fetchFelhely(){
                    const hely_tavaz = this.selectedFelrako;  // document.getElementById('selectedFelrako').value;
                    const postData = {
                        hely_tavaz: hely_tavaz  // Dynamischer Wert aus dem Filter
                       };
                      console.log(JSON.stringify(postData))
                        let url="/hely/list_hely.php";
                        const response = await axiosInstance.post(url, postData);
                        console.log("response",response);
                        if (response.status === 200) {
                            this.felhelyOptions = response.data.data;
                         //   console.log(this.felhelyOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }
                 }, 

                 async fetchLehely(){//**************************************************************************************** */
                    const Lhely_tavaz = this.selectedLerako;  // document.getElementById('selectedFelrako').value;
                    const postData = {
                        hely_tavaz: Lhely_tavaz  // Dynamischer Wert aus dem Filter
                       };
                    //  console.log(JSON.stringify(postData))

                    let url="/hely/list_hely.php";
                        const response = await axiosInstance.post(url, postData);
                        if (response.status === 200) {
                            this.lehelyOptions = response.data.data;
                         //   console.log(this.felhelyOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }
                 }, 
                 
                 async fetchArTipus() {//************************************************************************************ */
                    const tip_megn_filter = document.getElementById('tip_filter').value;
                    const postData = {
                        tip_cegaz: 1,  // Fixierter Wert für v_cegaz
                        tip_megn_filter: tip_megn_filter  // Dynamischer Wert aus dem Filter
                       };
                    //  console.log(JSON.stringify(postData))
                    let url="/tip/list_tip.php";
                        const response = await axiosInstance.post(url, postData);
                        if (response.status === 200) {
                            this.tipOptions = response.data.data;
                         //   console.log(this.felhelyOptions);
                        } else {
                            this.errorMessage = 'Fehler beim Aufgeben der Bestellung: ' + response.data.message;
                        }
                },


                async fetchAlvArTipus() {//********************************************************************************* */
                        const alvTip_megn_filter = document.getElementById('alvTip_filter').value;
                        const postData = {
                            tip_cegaz: 1,  // Fester Wert für den Firmenkunden
                            tip_megn_filter: alvTip_megn_filter  // Wert aus dem Filter-Input
                        };
                        console.log(JSON.stringify(postData));  // Debugging

                        let url = "/tip/list_tip.php";  // API-Endpunkt
                        try {
                            const response = await axiosInstance.post(url, postData);
                            if (response.status === 200) {
                                this.alvTipOptions = response.data.data;  // Daten in die Option-Liste laden
                                console.log('Erfolgreich geladen:', this.alvTipOptions);  // Debugging
                            } else {
                                console.error('Fehler beim Laden der Daten:', response.data.message);
                                this.errorMessage = 'Fehler beim Abrufen der Daten: ' + response.data.message;
                            }
                        } catch (error) {
                            console.error('Fehler bei der Anfrage:', error);
                            this.errorMessage = 'Fehler beim Abrufen der Daten: ' + error.message;
                        }
                    },


                setStandardValue(standardIndex) {//**************************************************** */
                          this.megrendeloOptions = [];
                          let standardValue;

                          switch (standardIndex) {
                            case 1:
                              standardValue = {
                                az: '2727',
                                v_kod: '3221',
                                v_nev1: 'Viterra Hungary Kft.',
                                v_varos: 'Budapest',
                                v_cegaz: '1'
                              };
                              this.selectedMegrendelo = '3221';
                              this.selectedMegrendeloName = standardValue.v_nev1;
                              break;
                            case 2:
                              standardValue = {
                                az: '355',
                                v_kod: '506',
                                v_nev1: 'Cargill Magyarország Zrt.',
                                v_varos: 'Budapest',
                                v_cegaz: '1'
                              };
                              this.selectedMegrendelo = '506';
                              this.selectedMegrendeloName = standardValue.v_nev1;
                              break;
                            case 3:
                              standardValue = {
                                az: '1341',
                                v_kod: '1602',
                                v_nev1: 'UBM Grain Zrt.',
                                v_varos: 'Pilisvörösvár',
                                v_cegaz: '1'
                              };
                              this.selectedMegrendelo = '1602';
                              this.selectedMegrendeloName = standardValue.v_nev1;
                              break;
                            default:
                              standardValue = null;
                          }

                          if (standardValue) {
                            this.megrendeloOptions.push(standardValue);
                          }
                        },


                        setStandardValueFelrako(standardIndex) {//***************************************************** */
                                    this.felrakoOptions = [];
                                    let standardValue;

                                    switch (standardIndex) {
                                        case 1:
                                            standardValue = {
                                                az: '53',
                                                tav_az: '125',
                                                tav_megn: 'Slo-Koper',
                                                tav_cegaz: '1'
                                            };
                                            this.selectedFelrako = '125';
                                            this.selectedFelrakoName = standardValue.tav_megn;
                                            break;
                                        case 2:
                                            standardValue = {
                                                az: '1352',
                                                tav_az: '1516',
                                                tav_megn: 'H-Foktő',
                                                tav_cegaz: '1'
                                            };
                                            this.selectedFelrako = '1516';
                                            this.selectedFelrakoName = standardValue.tav_megn;
                                            break;
                                        case 3:
                                            standardValue = {
                                                az: '72',
                                                tav_az: '153',
                                                tav_megn: 'H-Szabadegyháza',
                                                tav_cegaz: '1'
                                            };
                                            this.selectedFelrako = '153';
                                            this.selectedFelrakoName = standardValue.tav_megn;
                                            break;
                                        default:
                                            standardValue = null;
                                    }
                                    if (standardValue) {
                                        this.felrakoOptions.push(standardValue);
                                    }
                                }
                                ,

                                setStandardValueLerako(standardIndex) {//********************************************************************* */
                                      this.lerakoOptions = [];
                                      let standardValue;

                                      switch (standardIndex) {
                                          case 1:
                                              standardValue = {
                                                  az: '248',
                                                  tav_az: '382',
                                                  tav_megn: 'H-Dunaföldvár',
                                                  tav_cegaz: '1'
                                              };
                                              this.selectedLerako = '382';
                                              this.selectedLerakoName = standardValue.tav_megn;  // Name setzen
                                              break;
                                          case 2:
                                              standardValue = {
                                                  az: '72',
                                                  tav_az: '153',
                                                  tav_megn: 'H-Szabadegyháza',
                                                  tav_cegaz: '1'
                                              };
                                              this.selectedLerako = '153';
                                              this.selectedLerakoName = standardValue.tav_megn;  // Name setzen
                                              break;
                                          case 3:
                                              standardValue = {
                                                  az: '205',
                                                  tav_az: '335',
                                                  tav_megn: 'H-Kiskunfélegyháza',
                                                  tav_cegaz: '1'
                                              };
                                              this.selectedLerako = '335';
                                              this.selectedLerakoName = standardValue.tav_megn;  // Name setzen
                                              break;
                                          default:
                                              standardValue = null;
                                      }
                                      if (standardValue) {
                                          this.lerakoOptions.push(standardValue);
                                      }
                                  }
                                  ,

                                  setStandardValueCikk(standardIndex) {//*************************************************************** */
                                            this.cikkOptions = [];
                                            let standardValue;
                                            
                                            switch (standardIndex) {
                                                case 1:
                                                    standardValue = {
                                                        az: '20',
                                                        cikk_az: '42',
                                                        cikk_megn: 'szójadara',
                                                        cikk_cegaz: '1'
                                                    };
                                                    this.selectedCikk = '42';
                                                    this.selectedCikkName = standardValue.cikk_megn; // Name setzen
                                                    break;
                                                case 2:
                                                    standardValue = {
                                                        az: '6',
                                                        cikk_az: '22',
                                                        cikk_megn: 'kukorica',
                                                        cikk_cegaz: '1'
                                                    };
                                                    this.selectedCikk = '22';
                                                    this.selectedCikkName = standardValue.cikk_megn; // Name setzen
                                                    break;
                                                case 3:
                                                    standardValue = {
                                                        az: '183',
                                                        cikk_az: '284',
                                                        cikk_megn: 'napradara',
                                                        cikk_cegaz: '1'
                                                    };
                                                    this.selectedCikk = '284';
                                                    this.selectedCikkName = standardValue.cikk_megn; // Name setzen
                                                    break;
                                                default:
                                                    standardValue = null;
                                            }
                                            if (standardValue) {
                                                this.cikkOptions.push(standardValue);
                                            }
                                        },

                                        setStandardValueTip(standardIndex) {//***************************************************************** */
                                                        this.tipOptions = [];
                                                        let standardValue;

                                                        switch (standardIndex) {
                                                            case 1:
                                                                standardValue = {
                                                                    az: '2',
                                                                    tip_az: '1',
                                                                    tip_megn: 'Fuvar',
                                                                    tip_egyseg: 'Ft/to',
                                                                    tip_cegaz: '1',
                                                                    m_tipmenny:1
                                                                };
                                                                this.selectedTip = '1';
                                                                this.selectedTipName = `${standardValue.tip_megn} - ${standardValue.tip_egyseg}`; // Name und Einheit setzen
                                                                break;
                                                            case 2:
                                                                standardValue = {
                                                                    az: '4',
                                                                    tip_az: '3',
                                                                    tip_megn: 'Fuvar',
                                                                    tip_egyseg: '€/to',
                                                                    tip_cegaz: '1',
                                                                    m_tipmenny:1
                                                                };
                                                                this.selectedTip = '3';
                                                                this.selectedTipName = `${standardValue.tip_megn} - ${standardValue.tip_egyseg}`; // Name und Einheit setzen
                                                                break;
                                                            default:
                                                                standardValue = null;
                                                        }
                                                        if (standardValue) {
                                                            this.tipOptions.push(standardValue);
                                                        }
                                                    },
                                         setStandardValueAlvTip(standardIndex) {//****************************************************** */
                                                  this.alvTipOptions = [];
                                                  let standardValue;

                                                  switch (standardIndex) {
                                                      case 1:
                                                          standardValue = {
                                                              az: '2',
                                                              tip_az: '1',
                                                              tip_megn: 'Fuvar',
                                                              tip_egyseg: 'Ft/to',
                                                              tip_cegaz: '1',
                                                              m_tipmenny:1
                                                          };
                                                          this.selectedAlvTip = standardValue.tip_az; // Setze die ID
                                                          this.selectedAlvTipName = standardValue.tip_egyseg; // Setze die Einheit
                                                          break;
                                                      case 2:
                                                          standardValue = {
                                                              az: '4',
                                                              tip_az: '3',
                                                              tip_megn: 'Fuvar',
                                                              tip_egyseg: '€/to',
                                                              tip_cegaz: '1',
                                                              m_tipmenny:1
                                                          };
                                                          this.selectedAlvTip = standardValue.tip_az; // Setze die ID
                                                          this.selectedAlvTipName = standardValue.tip_egyseg; // Setze die Einheit
                                                          break;
                                                      default:
                                                          standardValue = null;
                                                  }
                                                  if (standardValue) {
                                                      this.alvTipOptions.push(standardValue);
                                                  }
                                              },
                                              selectAlvTip(itemalv) {//******************************************************** */
                                                this.selectedAlvTip = itemalv.tip_az; // Integer ID
                                                this.selectedAlvTipName = itemalv.tip_egyseg; // Einheitspreis als String
                                            },

                              clear_to() { // Entferne den Parameter 'event'
                           
                                  this.suly_ertek = ''; 
                              },
                              clear_km() { // Entferne den Parameter 'event'
                           
                                  this.km_ertek = ''; 
                              },
                              clear_ar() { // Entferne den Parameter 'event'
                           
                              this.megrendelo_ar_ertek = ''; 
                            },
                            clear_alvar() { // Entferne den Parameter 'event'
                           
                           this.alv_dij_ertek = ''; 
                         }


   

// methods vége
    },
    computed: {
        ...mapGetters(['getUserData']),
        fsz() {
            return this.getUserData ? this.getUserData.fsz : null;
        },
        szem_az() {
            return this.getUserData ? this.getUserData.szem_az : null;
        },
        csoport() {
            return this.getUserData ? this.getUserData.csoport : null;
        },
        ceg_az() {
            return this.getUserData ? this.getUserData.ceg_az : null;
        }
  }


};
</script>

<style>

.container .container-fluid {
  padding-top: 0px;
  margin-top: 0px;
}

.container.mt-5 {
    margin-top: 0px !important; /* Setzt das Margin auf einen kleineren Wert */
}

h1 {
  margin-top: 0px !important; /* Verringere den Abstand nach Bedarf */
}

.form-group.row.align-items-center {
    margin-bottom: 2px !important; /* Setze das Margin auf 0 und überschreibe alle anderen Regeln */
}



.selected-megrendelo,
.selected-felrako,
.selected-lerako {
  font-weight: bold;
  color: darkgreen;
}

.custom-btn-sm {
  padding: 2px 4px; /* Minimaleres Padding für eine engere Passform */
  font-size: 10px;
  line-height: 1.2;
}

.form-group.row {
  align-items: flex-end; /* Elemente am unteren Rand ausrichten */
  margin-bottom: 8px; /* Vermeiden Sie zu viel Außenabstand */
}

.input-group, .btn-group, .dropdown {
  display: flex;
  align-items: flex-end; /* Elemente am unteren Rand der Container ausrichten */
}

.btn-group button, .dropdown-toggle {
  height: 36px; /* Lassen Sie die Höhe sich natürlich anpassen */
  padding: 6px 12px; /* Standard-Padding für Bootstrap-Buttons, anpassen falls nötig */
}

.dropdown-toggle {
  align-self: stretch; /* Lässt den Dropdown-Button die Höhe seiner Nachbarn annehmen */
}

.input-group .form-control {
  height: auto; /* Vermeiden Sie feste Höhenangaben, falls diese Probleme verursachen */
}

.input-group-append {
  margin-left: 4px; /* Kleiner Abstand zwischen Eingabefeld und Button */
}
</style>
