import { createStore } from 'vuex'

// Enum für Benutzertypen
export const UserTypes = Object.freeze({
  ADMIN: 'admin',
  FUVAR_SZERVEZŐ: 'fuvarszervező',
  DISZPÉCSER: 'diszpécser'
});


export default createStore({
  state: {
    userData: {  
      szem_az: '',
      nev: '',
      fsz: '',
      ceg_az: '',
      first_sign: '',
      tipus: '',
      csoport: ''
    },
    order: {
      az: null,
      cegaz: null,
      datum: null,
      viszonylat: null,
      napifo_az: null,
      napimellek_az: null,
      tipus: null,
      megrendelo: null,
      cikk: null,
      szall_hatarido: null,
      hivatkozasi_szam: null,
      oldal:null,
      fo_megjegyzes:null,
      csoport:null,
      fsz:null
    },
    logParams: {  // Neue State für die ChangeLog-Parameter
      user: '',
      table_name: '',
      record_id: 0
    },
    truckData: {
      az: null,
      fo_az: null,
      rendsz_az: null,
      rendszam: '',
      potkocsi: '',
      felsegjel: '',
      fuvarozo: '',
      felrakas_napja: '',
      lerakas_napja: '',
      fel_suly: null,
      le_suly: null,
      rakodasi_szam: '',
      billenes_kod: '',
      fuv_az: null,
      datum: '',
      megjegyzes: '',
      sorrend: null,
      status: null,
      storno: null,
      made: '',
      felh: '',
      timest: '',
      mod_felh: '',
      sajat: null,
      fordulo: null,
      ev: null,
      ho: null,
      het: null,
      het_napja: null,
      m_tipmenny: null,
      ceg_az: null
    }
  },
  getters: {
    getTruckData: state => state.truckData,
    getUserData: state => state.userData,
    getorderData: state => state.order,
    getUserType: state => state.userData.tipus,  // Getter für den Benutzertyp
    isAdmin: state => state.userData.tipus === UserTypes.ADMIN,  // Prüfen, ob der Benutzer ein Admin ist
    isFuvarszervezo: state => state.userData.tipus === UserTypes.FUVAR_SZERVEZŐ,  // Prüfen, ob der Benutzer ein Fuvarszervező ist
    isDiszpecser: state => state.userData.tipus === UserTypes.DISZPÉCSER,  // Prüfen, ob der Benutzer ein Diszpécser ist
    getLogParams: state => state.logParams  // Getter für die ChangeLog-Parameter
  },
  mutations: {
    setTruckData(state, truckData) {
      state.truckData = truckData;
    },
      // Neue Mutation, um Truck-Daten zu leeren
  clearTruckData(state) {
    state.truckData = {
      az: null,
      fo_az: null,
      rendsz_az: null,
      rendszam: null,
      potkocsi: null,
      felsegjel: null,
      fuvarozo: null,
      felrakas_napja: null,
      lerakas_napja: null,
      fel_suly: null,
      le_suly: null,
      rakodasi_szam: null,
      billenes_kod: null,
      fuv_az: null,
      datum: null,
      megjegyzes: null,
      sorrend: null,
      status: null,
      storno: null,
      made: null,
      felh: null,
      timest: null,
      mod_felh: null,
      sajat: null,
      fordulo: null,
      ev: null,
      ho: null,
      het: null,
      het_napja: null,
      m_tipmenny: null,
      ceg_az: null
    };
  },
  setUserData(state, userData) {
      state.userData.szem_az = userData.szem_az;
      state.userData.nev = userData.nev;
      state.userData.fsz = userData.fsz;
      state.userData.ceg_az = userData.ceg_az;
      state.userData.first_sign = userData.firstSign;
      state.userData.tipus = userData.tipus;
      state.userData.csoport = userData.csoport;
    },
    setorderData(state, orderData) {
      state.order = orderData;
      console.log('orderData', orderData);
    },
    setLogParams(state, logParams) {  // Neue Mutation zum Setzen der ChangeLog-Parameter
      state.logParams = logParams;
    }
  },
  actions: {
    // Du kannst hier weitere Actions hinzufügen, falls notwendig
  },
  modules: {
    // Falls du dein Store in Module unterteilen möchtest
  }


})
