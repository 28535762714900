<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <!-- Logo hinzufügen -->
      <a class="navbar-brand" href="#">
        <img src="@/assets/bogos.png" alt="Firmenlogo" class="logo">
        <span class="ftr-text">FTR 0.1.40</span>
        <span class="fsz-text"> {{ fsz }}</span>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/menu">Start</router-link>
          </li>
        
          <li class="nav-item">
            <router-link class="nav-link" to="/osszesMegrendeles">Összes megrendelés</router-link>
          </li>
        
          <li class="nav-item">
            <router-link class="nav-link" to="/new_order">Új megrendelés</router-link>
          </li>
        
          <!-- Neuer Menüeintrag für Change Log -->
          <li class="nav-item">
            <router-link class="nav-link" to="/change-log">Eseménynapló</router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" to="/help">Help</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="index.html">Kijelentkezés</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>



<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getUserData']),
    fsz() {
      return this.getUserData ? this.getUserData.fsz : null;
    },
    // Hier holen wir den aktuellen Routen-Namen
    currentRouteName() {
      return this.$route.name;
    },
    // Hier holen wir den `az`, falls die Seite orderDetails aktiv ist
    currentAz() {
      return this.$route.params.az || 0;
    }
  },
  methods: {
    navigateToChangeLog() {
      let table_name = '';
      let record_id = 0;
      let user = '';

      // Prüfen, welche Seite aktiv ist und die Parameter entsprechend setzen
      if (this.currentRouteName === 'orderDetails') {
        table_name = 'ftr_megrendeles';
        record_id = this.currentAz;
      } else if (this.currentRouteName === 'MenuForm') {
        user = this.fsz;
      }

      // Navigation zu ChangeLog mit den festgelegten Parametern
      this.$router.push({
        name: 'ChangeLog',
        query: {
          user: user,
          table_name: table_name,
          record_id: record_id
        }
      });
    }
  },
  name: 'NavigationBar'
};
</script>
  
  <style>
.logo {
  height: 30px;
  margin-right: 10px; /* Abstand zwischen Logo und Text */
}

.ftr-text {
  font-size: 0.75em; /* Verkleinern des Textes "FTR 0.1" */
  margin-right: 30px; /* Abstand zwischen "FTR 0.1" und "FSZ" */
}

.fsz-text {
  font-size: 1em; /* Standardgröße beibehalten oder anpassen */
  margin-left: auto; /* Zentrieren des FSZ-Texts innerhalb des Navigationsbereichs */
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav > a {
  display: flex;
  align-items: center;
  flex-grow: 1; /* Erlaubt dem Link-Element, verfügbaren Platz zu nutzen */
}

nav .navbar-collapse {
  flex-grow: 2;
}

.navbar-nav {
  justify-content: flex-end;
}
</style>
